import { createRouter, createWebHistory } from 'vue-router';
import { routes } from '@/router/config';

const routers = createRouter({
    // history: createWebHistory('/whln-admin/'),
    history: createWebHistory('/'),
    routes: routes,
    // base: '/whln-admin'
});
routers.beforeEach((to, from, next) => {
    if(to.path == '/user/addPackage') {
        from.meta.activeKey ?to.meta.activeKey = from.meta.activeKey :to.meta.activeKey = sessionStorage.getItem('leftMenuActiveKey')
    }
    next()
})

export default routers;
